.calcApp[data-size="mini"]{
  top: 4%;
  left: 8%;
  width: 18%;
  height: 50%;
}

.aboutApp{
  top: 8%;
  left: 10%;
  width: auto;
  height: auto;
  border-radius: 4px;
  background: #eee;
}

.calcHis{
  display: none;
  transition: all ease-in-out 200ms;
  position: relative;
  box-sizing: border-box;
}

.calcApp[data-size="full"] .calcHis{
  width: 260px;
  display: flex;
}

.histCont{
  position: relative;
  flex-grow: 1;
  margin-right: 2px;
  overflow-y: scroll;
}

.hct{
  position: absolute;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  padding-right: 10px;
}

.valCont{
  box-sizing: border-box;
  height: 25%;
  font-size: 4.8em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  // justify-content: flex-end;
  padding: 0 32px;
  color: #333;
}

.eqCont{
  font-size: 0.22em;
  color: #888;
}

.msrVal{
  display: flex;
  padding: 16px 0;
  font-size: 0.64em;
  color: #888;

  div{
    margin: 0 24px;

    &:nth-child(n+3){
      color: #111;
      font-weight: 600;
    }
  }
}

.opcont{
  height: 70%;
  display: grid;
  flex-grow: 1;
  position: relative;
  box-sizing: border-box;
  grid-template-rows: repeat(6,minmax(0,1fr));
  grid-template-columns: repeat(4,minmax(0,1fr));

  &[data-err="true"]{

    .oper{
      color: #aaa;
      pointer-events: none;

      &:nth-child(3){
        pointer-events: auto;
        color: #444;
      }

      &:last-child{
        color: #ccc;
      }
    }
  }
}

.oper{
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: solid 2px #eee;
  background: #f9f9f9;
  font-size: 1.128em;
  font-weight: 600;
  cursor: pointer;

  *{
    pointer-events: none;
  }

  &:nth-child(-n + 8), &:nth-child(4n){
    background: rgba(255, 255, 255, 0.3);
    color: #444;
  }

  &:hover{
    background: rgba(136, 136, 136, 0.12);
  }

  &:last-child{
    background: rgba(0, 100, 255, 0.48);

    &:hover{
      background: rgba(0, 101, 255, 0.72);
    }
  }

  &:nth-child(4n){
    font-size: 1.8em;
  }

  &:nth-child(4){
    font-size: 1em;
    color: #333;
  }

  &:nth-child(8){
    font-size: 1.2em;
  }

  &:nth-child(12){
    font-family: monospace;
  }
}

.opow sup{
  margin-top: -8px;
}

.crossOp{
  font-family: monospace;
}

.calcApp[data-size="mini"]{
  .msrVal{
    display: none;
  }

  .valCont{
    font-size: 3em;
  }

  .vlcCont{
    font-size: 0.8em;
  }

  .oper{
    font-size: 0.88em;
  }
}

.canvaCont{
  position: relative;
  flex-grow: 1;
  z-index: 0;
}

.clickCont{
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paintTool{
  min-width: 60px;
  height: 60px;
  background: #fefefe;
  display: flex;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.25);
  padding: 0 12px;
}

.ptool{
  position: relative;
  width: 48px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 4px;
  transition: all 50ms ease-in-out;

  &[data-active="true"]{
    background: #ddd;
    &::before{
      content: "";
      position: absolute;
      top: 8px;
      right: 8px;
      width: 6px;
      height: 6px;
      border-radius: 10px;
      background: #222;
    }
  }

  &:hover{
    background: #eee;
  }

  svg{
    margin-top: 36%;
    max-height: 72%;
  }

  img{
    margin-top: 36%;
    width: 56%;
  }
}

.abCont{
  font-size: 9px;
  width: 19rem;
  color: #000;
  font-weight: 500;
}

.okbtn{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  div{
    border: solid 1px #007efb;
    padding: 2px 24px;
    border-radius: 4px;
    cursor: pointer;
  }
}
