@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  scrollbar-width: none;
}

*::selection{
  background: initial;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App{
  position: relative;
  width: 100vw;
  height: 100vh;
}

.w-max{
  width: max-content;
}

.h-max{
  height: max-content;
}

input{
  border: none;
  outline: none;
}

input::selection{
  background: #0074ff;
  color: #fefefe;
}

.redbd{
  box-sizing: border-box;
  border: solid 1px red;
}

.redbd0{
  border: solid 1px red;
}

.blbd{
  box-sizing: border-box;
  border: solid 1px blue;
}

.blbd0{
  border: solid 1px blue;
}

img[data-invert='true'], svg[data-invert='true']{
  filter: invert(1);
}

img[data-rounded='true'], svg[data-rounded='true']{
  border-radius: 1000px;
}

img[data-flip='true'], svg[data-flip='true']{
  transform: scale(-1,1);
}

.uicon img{
  transform-origin: center;
  transition: 400ms ease-in-out;
}

.uicon img[data-click="true"]:active{
  transform: scale(0.7);
  transition: 100ms ease-in-out;
}

.desktop{
  position: absolute;
  width: 100vw;
  height: calc(100vh - 39px);
  top: 0;
}

.dpShad{
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.25));
}

.mdShad{
  filter: drop-shadow(0 0 8px rgba(41, 41, 41, 0.1));
}

.ltShad{
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.25));
}

.prtclk *{
  pointer-events: none;
}

.handcr{
  cursor: pointer;
}

.none{
  display: none !important;
}

.text-xss{
  font-size: 0.64em;
}

.noscroll::-webkit-scrollbar {
  display: none;
}

.thinScroll::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent;
}

.thinScroll:hover::-webkit-scrollbar-thumb{
  background: rgba(32, 24, 148, 0.4);
}

.thinScroll::-webkit-scrollbar-thumb {
  width: 2px;
  height: 2px;
  border-radius: 10px;
  background-color: transparent;
}

@keyframes fadein {
  0%{
    opacity: 0;
  }

  99%{
    opacity: 0;
  }

  100%{
    opacity: 1;
  }
}

.hvdark:hover{
  background: rgba(0, 0, 0, 0.08);
  transition: all 200ms ease-in-out;
}
